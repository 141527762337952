<template>
    <div>
        <b-modal :visible="modals.shippingRates" size="lg" :title=" $t('general.shippingRates') "
            content-class="card form-rounded-inputs card-ecart" header-class="card-header" ok-disabled
            cancel-variant="none" centered @hidden="fnResetShippingRates()">

            <div class="row" v-for=" (storeRates,index) in shippingRates" :key="index">
                <div class="col-12">
                    <div class="  ">
                        <!-- <pre> {{storeRates}} </pre> -->
                        <!-- <div class="card-header bg-white">
                            <h6 class="f-12">
                                 <span class="f-w-500" v-text="$t('tables.store') + ': '"></span>
                                <span v-text="storeRates.store.name"></span>
                            </h6>
                        </div> -->
                        <div class="my-4">
                            <v-observer tag="form" ref="storesRates">
                                <v-validation rules="required" v-slot="{ errors }" :name=" storeRates.id ">
                                    <table class="table table-striped ecart-table table-hover table-bordered">
                                        <thead>
                                            <tr>
                                                <th >
                                                    <span class="f-w-600" v-text="storeRates.store.name"></span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody class="">
                                            <tr v-for=" (rate,indexRate) in storeRates.rates" :key="indexRate">
                                                <!-- <td class="pr-md-0">
                                                    
                                                </td> -->
                                                <td class="pl-md-0">
                                                    <label class="w-100 "
                                                        :class=" errors.length > 0 ? 'text-danger' : ''" :for="rate.id">
                                                        <div class="row">
                                                            <div class="col-2 col-lg-1 text-center">
                                                                <input type="radio" :id="rate.id" v-model="storeRates.option"  :value="rate.id">
                                                            </div>
                                                            <div class="col-10 col-lg-7">
                                                                <p>
                                                                    <span class="text-uppercase"
                                                                        v-text="rate.name"></span>
                                                                    <span
                                                                        v-text="' ( '  + rate.delivery.dateDifference + ' ' + $t('dates.' + rate.delivery.timeUnit ) + ' )' "></span>
                                                                </p>
                                                            </div>
                                                            <div class="col-12 col-lg-4">
                                                                <p class="text-right">
                                                                    <span
                                                                        v-text="fnFormatPrice(rate.amount, newCart.currency) "></span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </label>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div v-if="errors.length > 0">
                                        <p class="bg-danger p-1 border-danger">
                                            <small v-text="$t('general.selectCarrier')"></small>
                                        </p>
                                    </div>
                                </v-validation>
                            </v-observer>
                        </div>
                    </div>
                </div>
            </div>
            <no-data isLoading v-if="loading.rates" :loadingMessage="$t('general.quoting')"></no-data>
            <template v-slot:modal-footer>
                <div class="d-flex w-100">
                    <button @click=" fnResetShippingRates()" class="btn ml-auto"
                        v-text=" $t('general.button.close') "></button>
                    <button @click=" fnValdiateShippingRates()" class="btn btn-success"
                        :disabled="loading.updatignRates || loading.rates">
                        <i class="fa fa-spinner fa-spin " v-if="loading.updatignRates"></i>
                        <span v-else v-text=" $t('general.save') "></span>
                    </button>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import AddressForm from '@/components/AddressForm';
    import {
        mapActions,
        mapMutations,
        mapState
    } from 'vuex';
    export default {
        components: {
            AddressForm,
        },
        data() {
            return {
                disabledFields: ['addressName'],
            }
        },
        computed: {
            ...mapState('EcartNewCart', ['newCart', 'storeList', 'loading', 'modals', 'addresses', 'shippingRates']),
        },
        methods: {
            ...mapMutations('EcartNewCart', ['changeModal']),
            ...mapActions('EcartNewCart', ['fnApiUpdateAddress', 'fnApiUpdateRates']),
            fnResetShippingRates() {
                this.changeModal({
                    shippingRates: false
                })
            },
            async fnValdiateShippingRates() {
                let validations = 0;
                for await (let store of this.$refs['storesRates']) {
                    if (await store.validate()) {
                        validations++;
                    }
                }

                if (validations == this.shippingRates.length) {
                    console.log('All ok ');
                    this.fnApiUpdateRates();
                    // {{hostname}}/seller/carts/{{cart_id}}/shipping/rates/{id}
                } else {
                    console.log('Validation Error');
                }

            }
        }
    }
</script>