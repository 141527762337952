<template>
    <div class="h-100-vh">
        <div v-show=" !loading.data ">
            <div v-if="newCart.status != 'complete'">
                <NewCartProducts ref="productsComponent" />

                <div class="row alifn-items-center justify-content-center my-5" v-if="newCart.items.length > 0 ">
                    <div class="col-12 col-lg-6 col-xl-3 mb-2 mb-lg-none animated fadeIn "
                        v-show="!show.shippingAddress">
                        <button class="btn btn-outline-success btn-lg rounded-pill w-100"
                            @click="changeModal({shippingAddress : true}) "
                            v-text="$t('dashboard.order.shipping.shippingAddress')">
                        </button>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-3 mb-2 mb-lg-none animated fadeIn"
                        v-show="show.shippingAddress && !show.shippingRates">
                        <button class="btn btn-outline-success btn-lg rounded-pill w-100" @click="fnApiCreateRates()"
                            v-text="$t('messages.quoteShipping')">
                        </button>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-2 mb-2 mb-lg-none animated fadeIn "
                        v-show="!show.billingAddress">
                        <button class="btn btn-outline-info btn-lg rounded-pill w-100"
                            @click="changeModal({ billingAddress : true}) " v-text="$t('general.billing')">
                        </button>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-2  mb-2 mb-lg-none">
                        <button class="btn btn-light btn-lg rounded-pill w-100" @click="fnValidateData()">
                            <i class="fa fa-spinner fa-spin fa-sm " v-if="loading.save"></i>
                            <span v-text="$t('general.save')" v-else></span>
                        </button>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-2 mb-2 mb-lg-none">
                        <button class="btn btn-success btn-lg rounded-pill w-100" @click=" fnValidateData(true)"
                            :disabled="loading.order">
                            <i class="fa fa-spinner fa-spin fa-sm " v-if="loading.order"></i>
                            <span v-text="$t('general.button.create')" v-else></span>
                        </button>
                    </div>
                </div>
            </div>
            <NewCartComplete v-else />
        </div>
        <no-data isLoading v-show=" loading.data ">
        </no-data>
        <NewCartShippingAddressModal />
        <NewCartBillingAddressModal />
        <NewCartShippingRatesModal />
    </div>
</template>

<script>
    import {
        mapActions,
        mapMutations,
        mapState
    } from 'vuex'
    import NewCartProducts from "./components/new-cart-products.vue";
    import NewCartComplete from "./components/new-cart-complete.vue";

    import NewCartShippingAddressModal from "./components/new-cart-shipping-address-modal.vue";
    import NewCartBillingAddressModal from "./components/new-cart-billing-address-modal.vue";
    import NewCartShippingRatesModal from "./components/new-cart-shipping-rates-modal.vue";

    export default {
        components: {
            NewCartProducts,
            NewCartComplete,
            NewCartShippingAddressModal,
            NewCartBillingAddressModal,
            NewCartShippingRatesModal,
        },
        computed: {
            ...mapState('EcartNewCart', ['newCart', 'show', 'loading']),
        },
        methods: {
            ...mapActions('EcartNewCart', ['fnApiCreateCart', 'fnApiGetCart', 'fnApiGetStores', 'fnApiCreateOrder',
                'fnApiCreateRates', 'fnApiUpdateEmail'
            ]),
            ...mapMutations('EcartNewCart', ['changeModal','resetState']),

            async fnValidateData(create) {
                // Validate Products
                // Validate shipping addresss
                // Validate shipping rates
                if (await this.$refs.productsComponent.fnValidateEmail(create)) {
                    if (create) {
                        await this.fnApiCreateOrder();
                    }
                }
            }
        },
        beforeMount(){
            this.resetState();
        },
        async mounted() {

            await this.fnApiGetStores();
            if (this.$route.params.cartId) {
                this.fnApiGetCart(this.$route.params.cartId);
            } else {
                this.fnApiCreateCart();
            }
        }
    }
</script>