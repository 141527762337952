<template>
    <div>

        <div class="card bg-success my-4 mt-md-0 d-flex flex-column align-items-center justify-content-center d-md-none ">
            <div class="card-body">
                <p class="f-20 text-center">
                    <strong v-text=" newCart.status == 'complete' ?  ( $t('general.order') + ' :  ' + orderInformation.number) : '' "></strong>
                    <!-- <i class="fa fa-check-circle ml-2"></i> -->
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-12" v-for=" (suborder,index) in orderInformation.suborders " :key="index">
                <div class="card shadow-none mb-4">
                    <!-- <div class="card-header">
                        <div class="row justify-content-between">
                            <div class="col">
                                <h5 v-text="suborder.store_name "></h5>
                            </div>
                        </div>
                    </div> -->
                    <div class="card-body">
                        <table class="table table-striped m-0">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th v-text="suborder.store_name ">
                                    </th>
                                    <th class="text-center" v-text="$t('tables.quantity')"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for=" (item,index) in suborder.items" :key="item.id + index">
                                    <td>
                                        <img v-image="item.image_url" class="img-contain-40 border bg-white">
                                    </td>
                                    <td class="col">
                                        <p class=" ">
                                            <span v-text="item.name"> </span>
                                            <span v-text="item.variant_name"> </span>
                                        </p>
                                        <div class="row">
                                            <div class="col-auto pr-1">
                                                <p class=" m-0 ">
                                                    <strong class="f-w-500"
                                                        v-text=" $t('general.form.price') + ': ' "></strong>
                                                    <span class="text-success f-w-400"
                                                        v-text="fnFormatPrice(item.price , item.currency )"></span>
                                                    <small class="text-warning f-w-400 d-none d-md-inline ml-1"
                                                        v-if="item.base_discount > 0"
                                                        v-text="fnFormatPrice(item.base_discount, item.currency) + ' OFF'  "></small>
                                                </p>
                                            </div>
                                            <div class="col-auto pl-0">
                                                <div class="circle-20 bg-warning d-md-none ml-0"
                                                    v-if="item.base_discount > 0">
                                                    <i class="fa fa-sm fa-percentage " v-b-popover.hover.bottom="fnFormatPrice(item.base_discount, item.currency) + ' OFF' " ></i>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <p v-text="item.quantity"></p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="card-footer">
                        <p class="text-right">
                            <strong>
                                <router-link class="text-uppercase" :to="'/dashboard/orders/' + suborder.id "
                                    v-text=" '#' +  orderInformation.number + '-' + suborder.store_id.slice(-3) ">
                                </router-link>
                            </strong>
                        </p>
                    </div>
                </div>
                <hr class="mb-4">
            </div>
        </div>


        <div class="row justify-content-between">
            <div class="col-12 col-lg-6 col-xl-7 order-2 order-lg-1 ">
                <div class="card shadow-none " v-if=" show.billingAddress ">
                    <div class="card-body ">
                        <p class="f-w-500 text-info">
                            <i class="fa fa-file-invoice  mr-2  "></i>
                            <span v-text="$t('general.billingAddress')"></span>
                        </p>
                        <div class="text-muted">
                            <p>
                                <small class="f-w-500">
                                    <span
                                        v-text=" newCart.billing_address.first_name + ' ' + newCart.billing_address.last_name + ' - ' "></span>
                                    <span>
                                        <i class="fa fa-phone mr-1"></i>
                                        <span v-text=" newCart.billing_address.phone "></span>
                                    </span>
                                </small>
                                <br>
                                <small>
                                    <span v-text="fnFormatAddress( newCart.billing_address )"></span>
                                </small>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="card shadow-none" v-if="show.shippingAddress">
                    <div class="card-body">
                        <p class="f-w-500 text-success">
                            <i class="fa fa-map-marked-alt  mr-2  "></i>
                            <span v-text="$t('dashboard.order.shipping.shippingAddress')"></span>
                        </p>
                        <div class="text-muted">
                            <p>
                                <small class="f-w-500">
                                    <span
                                        v-text=" newCart.shipping_address.first_name + ' ' + newCart.shipping_address.last_name + ' - ' "></span>
                                    <span class="text-muted">
                                        <i class="fa fa-phone mr-1"></i>
                                        <span v-text=" newCart.shipping_address.phone "></span>
                                    </span>
                                </small>
                                <br>
                                <small>
                                    <span v-text="fnFormatAddress( newCart.shipping_address )"></span>
                                </small>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="card shadow-none" v-if="show.shippingRates">
                    <div class="card-body p-0">
                        <div class="row justify-content-between align-items-center px-3 pt-3">
                            <div class="col-8">
                                <p class="f-w-500 text-info ">
                                    <i class="fa fa-pallet mr-2"></i>
                                    <span v-text="$t('general.shipments')"></span>
                                </p>
                            </div>
                            <div class="col-4">
                                <p class="text-right">
                                    <i class=" fa fa-question-circle ml-1 text-info "
                                        v-b-popover.hover.top=" $t('messages.quoteShipment') "></i>
                                </p>
                            </div>
                        </div>
                        <table class="table ecart-table table-sm table-striped mb-3 mb-md-0">
                            <tbody>
                                <tr class="border-1" v-for=" (store) in newCart.shipping_items" :key="store.id">
                                    <td>
                                        <div class="row px-2">
                                            <div class="col-12 text-muted">
                                                <p class="m-0 f-w-500" v-text="store.store_name"></p>

                                                <div class="row text-muted">
                                                    <div class="col-12 col-xl-8">
                                                        <p>
                                                            <span class="text-capitalize"
                                                                v-text="store.rateSelected.name"></span>
                                                            <span
                                                                v-text="' ( '  + store.rateSelected.delivery.dateDifference + ' ' + $t('dates.' + store.rateSelected.delivery.timeUnit ) + ' )' "></span>
                                                        </p>
                                                    </div>
                                                    <div class="col-12 col-xl-4">
                                                        <p class="text-right">
                                                            <span
                                                                v-text="fnFormatPrice(store.rateSelected.amount, newCart.currency) "
                                                                v-if="store.rateSelected.amount > 0"></span>
                                                            <span class="f-w-600 text-success" v-else
                                                                v-text="$t('general.free')"></span>
                                                        </p>
                                                    </div>
                                                </div>

                                                <div class="animated fadeIn mt-2 text-muted" v-show=" false ">
                                                    <p>
                                                        <span
                                                            v-text=" store.origin.first_name + ' ' + store.origin.last_name "></span>
                                                        <span class="text-muted">
                                                            <i class="fa fa-phone ml-2 mr-1"></i>
                                                            <span class="f-w-500" v-text=" store.origin.phone "></span>
                                                        </span>
                                                    </p>
                                                    <p>
                                                        <span v-text="fnFormatAddress( store.origin )"></span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6 col-xl-5 order-1 order-lg-2">
                <div class="card shadow-none">
                    <div class="card-body">
                        <p>
                            <span class="f-w-500" v-text="$t('general.buyerEmail') + ': '"></span>
                            <span v-text="newCart.email"></span>
                        </p>
                    </div>
                </div>
                <div class="card shadow-none bg-light">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-6">
                                <p>
                                    <span class="f-w-500" v-text="'Subtotal: '"></span>
                                </p>
                            </div>
                            <div class="col-6">
                                <p class="text-right">
                                    <span v-text="fnFormatPrice( newCart.totals.subtotal , newCart.currency )"></span>
                                </p>
                            </div>

                            <div class="col-6">
                                <p>
                                    <span class="f-w-500" v-text=" $t('general.shipping') + ': '"></span>
                                </p>
                            </div>
                            <div class="col-6">
                                <p class="text-right">
                                    <span v-text="fnFormatPrice( newCart.totals.shipping , newCart.currency )"></span>
                                </p>
                            </div>

                            <div class="col-6">
                                <p>
                                    <span class="f-w-500" v-text="$t('general.discount') + ': '"></span>
                                </p>
                            </div>
                            <div class="col-6">
                                <p class="text-right">
                                    <span v-text=" fnFormatPrice( newCart.totals.discount , newCart.currency ) "></span>
                                </p>
                            </div>

                            <div class="col-6">
                                <p>
                                    <span class="f-w-500" v-text=" $t('general.form.tax') + ': '"></span>
                                </p>
                            </div>
                            <div class="col-6">
                                <p class="text-right">
                                    <span v-text=" fnFormatPrice( newCart.totals.tax , newCart.currency ) "></span>
                                </p>
                            </div>

                            <div class="col-12">
                                <hr>
                            </div>

                            <div class="col-6">
                                <p>
                                    <span class="f-w-500" v-text="'Total: '"></span>
                                </p>
                            </div>
                            <div class="col-6">
                                <p class="text-right">
                                    <span v-text="fnFormatPrice( newCart.totals.total , newCart.currency )"></span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {
        mapState
    } from 'vuex'
    export default {
        computed: {
            ...mapState('EcartNewCart', ['newCart', 'show', 'orderInformation']),
        }
    }
</script>
<style lang="scss" scoped>
    .produsct-list {
        height: 300px;
        overflow-y: auto;
        overflow-x: hidden;
    }
</style>