<template>
    <div>
        <b-modal :visible="modals.shippingAddress" size="lg" :title=" $t('dashboard.order.shipping.shippingAddress') "
            content-class="card form-rounded-inputs card-ecart" header-class="card-header" ok-disabled
            cancel-variant="none" centered @hidden="fnResetShippingAddress()">
            <AddressForm ref="shippingAddress" :addressInformation="addresses.shippingAddress"
                :disabledList="disabledFields" />
            <template v-slot:modal-footer>
                <div class="d-flex w-100">
                    <button @click=" fnResetShippingAddress()" class="btn ml-auto"
                        v-text=" $t('general.button.close') "></button>
                    <button @click=" fnValdiateShippingAddress()" class="btn btn-success"
                        :disabled="loading.shippingAddress">
                        <i class="fa fa-spinner fa-spin " v-if="loading.shippingAddress"></i>
                        <span v-else v-text=" $t('general.save') "></span>
                    </button>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import AddressForm from '@/components/AddressForm';
    import {
        mapActions,
        mapMutations,
        mapState
    } from 'vuex';
    export default {
        components: {
            AddressForm,
        },
        data() {
            return {
                disabledFields: ['addressName'],
            }
        },
        computed: {
            ...mapState('EcartNewCart', ['newCart', 'storeList', 'loading', 'modals', 'addresses']),
        },
        methods: {
            ...mapMutations('EcartNewCart', ['changeModal']),
            ...mapActions('EcartNewCart', ['fnApiUpdateAddress']),
            fnResetShippingAddress() {
                this.changeModal({
                    shippingAddress: false
                })
            },
            async fnValdiateShippingAddress() {
                let shippingAddress = null;
                shippingAddress = await this.$refs.shippingAddress.fnValidateData();
                if (shippingAddress == null) {
                    return false;
                }
                delete shippingAddress.id;
                delete shippingAddress.address_name;
                if (shippingAddress.state.code) {
                    delete shippingAddress.state.name;
                }
                this.fnApiUpdateAddress([shippingAddress, 'shippingAddress']);
            }
        }
    }
</script>